<template>
  <header :class="{ white: isHomePage && !isMenuVisible }">
    <nav class="navbar navbar-expand" :class="{'no-border': noBorder }">
      <router-link class="text-reset text-decoration-none" to="/">
        <div class="text-logo" title="Locations in Ukraine">Locations</div>
      </router-link>

      <ul class="nav navbar-nav flex-grow-1 justify-content-center larger-text d-none d-lg-flex">

        <li class="nav-item nav-item-higlight nav-item-padded pr-4" data-toggle="collapse" data-target="#navbarToggler">
          <router-link to="/locations" class="nav-link menu-item animated-link">
            {{ $t('sectionLinks.locations') }}
          </router-link>
        </li>

        <li class="nav-item nav-item-higlight nav-item-padded pr-4" data-toggle="collapse" data-target="#navbarToggler">
          <router-link
            :to="{name: 'incentivesPage', query: {step: 'lawIncentive'}}"
            class="nav-link menu-item animated-link"
            :class="{'router-link-exact-active': $route.name == 'incentivesPage' }"
            >
            {{ $t('sectionLinks.incentives') }}
          </router-link>
        </li>

        <li class="nav-item nav-item-higlight nav-item-padded pr-4" data-toggle="collapse" data-target="#navbarToggler">
          <router-link to="/partners/" class="nav-link menu-item animated-link">
            {{ $t('sectionLinks.partners') }}
          </router-link>
        </li>

      </ul>

      <ul class="nav navbar-nav justify-content-end align-items-center larger-text d-none d-lg-flex">
        <li class="dropdown dropdown-user">
          <language-selector class="language-selector d-inline-block mr-4"> | </language-selector>

          <template v-if="$auth.user.authenticated">
            <a class="d-inline nav-link text-nowrap" data-toggle="dropdown" role="button" aria-expanded="true">
              <avatar class="d-inline-block align-middle" :src="avatarUrl" :size="30" v-if="avatarUrl"/>
              <avatar class="d-inline-block align-middle" :username="avatarName" :size="30" v-else/>
              {{ avatarName }}
            </a>

            <div class="dropdown-menu dropdown-menu-right dropdown-menu-black text-nowrap mt-2" role="menu">
              <span class="dropdown-menu-arrow"></span>

              <router-link class="dropdown-item nav-link px-4" to="/profile/">
                <i class="fas fa-user mr-2"></i>
                {{ $t('header.personalInfo') }}
              </router-link>

              <router-link class="dropdown-item nav-link px-4" to="/security/">
                <i class="fas fa-shield-alt mr-2"></i>
                {{ $t('header.loginAndSecurity') }}
              </router-link>

              <router-link class="dropdown-item nav-link px-4" to="/my-locations/" v-if="$auth.user.canAddLocations">
                <i class="fas fa-map-marker-alt mr-2"></i>
                {{ $t('header.myLocations') }}
              </router-link>

              <div class="border-top my-2"></div>

              <a href="/logout/" class="dropdown-item nav-link px-4" @click.prevent="logout">
                <i class="fas fa-sign-out-alt mr-2"></i>
                {{ $t('header.signOut') }}
              </a>
            </div>
          </template>
          <template v-else>
            <router-link to="/login/" class="nav-link menu-item animated-link pr-0">
              {{ $t('header.login') }}
            </router-link>
          </template>
        </li>
      </ul>

      <button class="btn-navbar ml-auto d-lg-none" aria-label="Toggle menu" @click="toggleMenu">
        <i class="fas fa-times" v-if="isMenuVisible"></i>
        <i class="fas fa-bars" v-else></i>
      </button>
    </nav>

    <div class="mobile-menu d-lg-none" :class="{ show: isMenuVisible }">
      <div class="h-50">
        <div class="h-75 p-3 d-flex flex-column">
            <router-link to="/locations" class="text-reset">
              {{ $t('sectionLinks.locations') }}
            </router-link>
            <router-link
              :to="{name: 'incentivesPage', query: {step: 'lawIncentive'}}"
              class="text-reset"
              :class="{'router-link-exact-active': $route.name == 'incentivesPage' }"
              >
              {{ $t('sectionLinks.incentives') }}
            </router-link>
            <router-link to="/partners/" class="text-reset">
              {{ $t('sectionLinks.partners') }}
            </router-link>
        </div>
        <div class="h-25">
          <div class="h-100 p-3 d-flex align-items-center">
            <language-selector class="language-selector"> | </language-selector>
          </div>
        </div>
      </div>

      <div class="h-50 background-black" v-if="$auth.user.authenticated">
        <div class="h-75 p-3 d-flex flex-column">

            <div class="d-inline text-nowrap">
              <avatar class="d-inline-block align-middle mr-2" :src="avatarUrl" :size="30" v-if="avatarUrl"/>
              <avatar class="d-inline-block align-middle mr-2" :username="avatarName" :size="30" v-else/>
              {{ avatarName }}
            </div>

          <router-link class="text-white" to="/profile/">
            <i class="fas fa-user mr-2"></i>
            {{ $t('header.personalInfo') }}
          </router-link>

          <router-link class="text-white" to="/security/">
            <i class="fas fa-shield-alt mr-2"></i>
            {{ $t('header.loginAndSecurity') }}
          </router-link>

          <router-link class="text-white" to="/my-locations/" v-if="$auth.user.canAddLocations">
            <i class="fas fa-map-marker-alt mr-2"></i>
            {{ $t('header.myLocations') }}
          </router-link>
        </div>
        <div class="h-25 border-top p-3 d-flex align-items-center">
          <a href="/logout/" class="text-white" @click.prevent="logout">
            <i class="fas fa-sign-out-alt mr-2"></i>
            {{ $t('header.signOut') }}
          </a>
        </div>
      </div>

      <div class="h-50" v-else>
        <div class="h-75"></div>
        <div class="h-25 background-black p-3 d-flex align-items-center">
          <router-link to="/login/" class="text-white">
            {{ $t('header.login') }}
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

header {
  color: $black;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.23px;
  position: absolute;
  width: 100%;
  transition: all 0.3s;
  ::v-deep a.nav-link {
    color: $black;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.35px;
    white-space: nowrap;
    &.router-link-exact-active {
      color: $gray-400;
      pointer-events: none;
    }
  }
  .navbar {
    max-width: calc(1030px - 2rem);
    margin: 0 auto;
    z-index: 10;
    background-color: $white;
    padding-left: 0;
    padding-right: 0;
    &.no-border {
      border-bottom: none;
    }
  }

  .text-logo {
    font-size: 26px;
    font-weight: 800;
    letter-spacing: -0.7px;
    z-index: 3;
  }
  .language-selector {
    color: $gray-400;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.35px;
    cursor: default;
  }
  .dropdown-user {
    i {
      display: inline-block;
      width: 14px;
      text-align: center;
    }
  }

  .btn-navbar {
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 20px;
    color: $black;
    z-index: 2;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.25rem;
  }

  .mobile-menu {
    position: fixed;
    top: -101vh;
    bottom: 101vh;
    left: 0;
    right: 0;
    background-color: $white;
    z-index: 9;
    transition: all 0.3s;
    padding-top: 57px;
    &.show {
      top: 0;
      bottom: 0;
    }
    a {
      font-size: 20px;
      font-weight: 600;
      line-height: 2.5;
      letter-spacing: -0.5px;
    }
    i {
      display: inline-block;
      width: 30px;
      text-align: center;
    }
    .language-selector ::v-deep {
      a, span {
        font-size: 16px;
      }
    }
    .background-black {
      color: $white;
      background-color: black;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.4px;
      line-height: 3;
      a {
        font-size: 16px;
        font-weight: normal;
        letter-spacing: -0.4px;
        line-height: 2.5;
      }
      i {
        color: $gray-700;
      }
      .border-top {
        border-top-color: $gray-800 !important;
      }
    }
  }

  &.white {
    color: $white;
    ::v-deep a.nav-link {
      color: $white;
    }
    .navbar {
      background-color: transparent;
      border-bottom: none;
    }
    .logo {
      z-index: 2;
    }
    ::v-deep .language-selector {
      span {
        opacity: 0.3;
      }
    }
    .btn-navbar {
      color: $white;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 2;
      outline: none;
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }
}

@media (max-width: 1030px) {
  header {
    .navbar {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

</style>

<script>
import LanguageSelector from '@/components/molecule/LanguageSelector';
import Avatar from 'vue-avatar';

const borderlessRoutes = [
  'View Location',
  'whyUkrainePage',
  'aboutProjectPage',
  'incentivesPage',
  'partnersPage',
  'permitsPage',
  'productionsPage'
];

export default {
  components: {
    LanguageSelector,
    Avatar
  },
  data() {
    return {
      isMenuVisible: false
    }
  },
  watch: {
    $route() {
      this.hideMenu();
    }
  },
  beforeDestroy() {
    this.hideMenu();
  },
  computed: {
    isHomePage() {
      return this.$route.name == 'Home';
    },
    avatarName() {
      if (this.$auth.user?.userName) {
        return this.$auth.user.userName;
      }
      if (this.$auth.user?.email) {
        return this.$auth.user.email.split('@')[0];
      }
      return '';
    },
    avatarUrl() {
      return this.$auth.user?.avatarUrl || null;
    },
    noBorder() {
      return borderlessRoutes.includes(this.$route.name) && !this.isMenuVisible;
    }
  },
  methods: {
    logout() {
      this.$auth.logout(this);
      this.hideMenu();
    },
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
      $('body').toggleClass('overflow-hidden');
    },
    hideMenu() {
      this.isMenuVisible = false;
      $('body').removeClass('overflow-hidden');
    }
  }
};
</script>
