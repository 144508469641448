import jsonwebtoken from 'jsonwebtoken';
import Cookies from 'js-cookie';
import Vue from "vue";

const COOKIE_NAME = 'jwt';

export default {
  user: new Vue({
    data: {
      authenticated: false,
      userName: '',
      email: '',
      phoneNumber: '',
      canAddLocations: false,
      avatarUrl: null
    }
  }),

  async checkAuth() {
    const jwt = Cookies.get(COOKIE_NAME);

    if (!jwt) {
      this.user.authenticated = false;
      this.user.userName = '';
      this.user.email = '';
      this.user.phoneNumber = '';
      this.user.canAddLocations = false;
      this.user.avatarUrl = null;
      return;
    }

    const result = await Vue.prototype.$restClient.get({
      url: '/auth/verify/'
    });

    if (!result.success) {
      this.user.authenticated = false;
      this.user.userName = '';
      this.user.email = '';
      this.user.phoneNumber = '';
      this.user.canAddLocations = false;
      this.user.avatarUrl = null;
      return;
    }

    this.user.authenticated = true;
    this.user.userName = result.userName;
    this.user.email = result.email;
    this.user.phoneNumber = result.phoneNumber;
    this.user.canAddLocations = result.canAddLocations;
    this.user.avatarUrl = result.avatarUrl;
  },

  async refresh() {
    const jwt = Cookies.get(COOKIE_NAME);

    if (!jwt) {
      this.user.authenticated = false;
      this.user.userName = '';
      this.user.email = '';
      this.user.phoneNumber = '';
      this.user.canAddLocations = false;
      this.user.avatarUrl = null;
      return;
    }

    const result = await Vue.prototype.$restClient.get({
      url: '/auth/refresh/'
    });

    if (!result.success) {
      this.user.authenticated = false;
      this.user.userName = '';
      this.user.email = '';
      this.user.phoneNumber = '';
      this.user.canAddLocations = false;
      this.user.avatarUrl = null;
      return;
    }

    this.user.authenticated = true;
    this.user.userName = result.userName;
    this.user.email = result.email;
    this.user.phoneNumber = result.phoneNumber;
    this.user.canAddLocations = result.canAddLocations;
    this.user.avatarUrl = result.avatarUrl;
  },

  async login(credentials) {
    const loginResult = await Vue.prototype.$restClient.post({
      url: '/auth/login/',
      data: credentials
    });

    if (!loginResult.success) {
      console.log('Login error', loginResult);
      return {
        success: false,
        error: 'Login error',
        loginResult
      };
    }

    const jwt = Cookies.get(COOKIE_NAME);

    if (!jwt) {
      this.user.authenticated = false;
      this.user.userName = '';
      this.user.email = '';
      this.user.phoneNumber = '';
      this.user.canAddLocations = false;
      this.user.avatarUrl = null;

      return {
        success: false,
        error: 'JWT storage error',
        loginResult
      };
    }

    const jwtDecoded = jsonwebtoken.decode(jwt);

    this.user.authenticated = true;
    this.user.userName = jwtDecoded.userObject.userName;
    this.user.email = jwtDecoded.userObject.email;
    this.user.phoneNumber = jwtDecoded.userObject.phoneNumber;
    this.user.canAddLocations = jwtDecoded.userObject.canAddLocations;
    this.user.avatarUrl = jwtDecoded.userObject.avatarUrl;

    return { success: true, loginResult };
  },

  logout(context) {
    Cookies.remove(COOKIE_NAME);
    this.user.authenticated = false;
    this.user.userName = '';
    this.user.email = '';
    this.user.phoneNumber = '';
    this.user.canAddLocations = false;
    this.user.avatarUrl = null;

    context.$router.push('/').catch(() => {});
  }
}