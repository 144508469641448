<template>
  <div>
    <template v-for="(lang, i) in langs">
      <slot v-if="i"><span :key="`Separator${i}`" class="mx-1">&bullet;</span></slot>
      <span class="d-inline text-uppercase px-0" :key="`Lang${i}`" v-if="$i18n.locale == lang">
        {{ lang }}
      </span>
      <a
        href="#"
        class="nav-link animated-link text-uppercase px-0"
        :key="`Lang${i}`"
        @click.prevent="$i18n.locale = lang"
        :class="{ active: $i18n.locale == lang }"
        v-else
      > {{ lang }} </a>
    </template>
  </div>
</template>

<script>
const LOCAL_STORAGE_LANG_KEY_NAME = 'app-lang';

export default {
  data () {
    return { langs: Object.keys(this.$i18n.messages) }
  },
  created () {
    const lang = window.localStorage.getItem(LOCAL_STORAGE_LANG_KEY_NAME);

    if (lang) {
      this.$i18n.locale = lang;
      return;
    }

    if (!lang && window.navigator.languages?.length) {
      window.navigator.languages.some(language => {
        if (this.langs.includes(language)) {
          this.$i18n.locale = language;
          return true;
        }
        return false;
      });
    }

    window.localStorage.setItem(LOCAL_STORAGE_LANG_KEY_NAME, this.$i18n.locale);
  },
  watch: {
    '$i18n.locale': function(val) {
      window.localStorage.setItem(LOCAL_STORAGE_LANG_KEY_NAME, val);
    }
  }
}
</script>
