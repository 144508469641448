import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from 'js-cookie';
import auth from '@/auth';

const Home = () => import ("@/components/page/Home.vue");
const Profile = () => import ("@/components/page/Profile.vue");
const Security = () => import ("@/components/page/Security.vue");
const AboutProjectPage = () => import ("@/components/page/AboutProject.vue");
const IncentivesPage = () => import ("@/components/page/Incentives.vue");
const Locations = () => import ("@/components/page/Locations.vue");
const MyLocations = () => import ("@/components/page/MyLocations.vue");
const LocationForm = () => import ("@/components/page/LocationForm.vue");
const LocationView = () => import ("@/components/page/LocationView.vue");
const PartnersPage = () => import ("@/components/page/Partners.vue");
const PermitsPage = () => import ("@/components/page/Permits.vue");
const ProductionsPage = () => import ("@/components/page/Productions.vue");
const RegionPage = () => import ("@/components/page/Region.vue");
const WhyUkrainePage = () => import ("@/components/page/WhyUkraine.vue");
const Login = () => import ('@/components/auth/Login.vue');
const Registration = () => import ('@/components/auth/Registration.vue');
const Forgot = () => import ('@/components/auth/Forgot.vue');
const TermsOfService = () => import ('@/components/page/TermsOfService.vue');
const PrivacyPolicy = () => import ('@/components/page/PrivacyPolicy.vue');
const ErrorPage = () => import ('@/components/page/ErrorPage');

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: '/profile/',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/security/',
    name: 'Security',
    component: Security
  },
  {
    path: "/locations",
    name: "Locations",
    component: Locations,
    props: true
  },
  {
    path: "/my-locations",
    name: "My Locations",
    component: MyLocations,
    meta: {
      auth: true
    }
  },
  {
    path: "/my-locations/:locationId",
    name: "Edit Location",
    component: LocationForm,
    props: true,
    meta: {
      auth: true
    }
  },
  {
    path: "/add-location",
    name: "Add Location",
    component: LocationForm,
    meta: {
      auth: true
    }
  },
  {
    path: '/region/:regionId/',
    name: 'regionPage',
    component: RegionPage,
    props: true
  },
  {
    path: '/why-ukraine/',
    name: 'whyUkrainePage',
    component: WhyUkrainePage,
  },
  {
    path: '/about-project/',
    name: 'aboutProjectPage',
    component: AboutProjectPage,
  },
  {
    path: '/incentives/',
    name: 'incentivesPage',
    component: IncentivesPage,
  },
  {
    path: '/partners/',
    name: 'partnersPage',
    component: PartnersPage
  },
  {
    path: '/permits/',
    name: 'permitsPage',
    component: PermitsPage
  },
  {
    path: '/terms-of-service/',
    name: 'termsOfServicePage',
    component: TermsOfService
  },
  {
    path: '/privacy-policy/',
    name: 'privacyPolicyPage',
    component: PrivacyPolicy
  },
  {
    path: '/productions/',
    name: 'productionsPage',
    component: ProductionsPage
  },
  {
    path: "/locations/:locationId",
    name: "View Location",
    component: LocationView,
    props: true
  },
  {
    path: '/login/',
    name: 'Login',
    component: Login,
    props: true
  },
  {
    path: '/registration/',
    name: 'Registration',
    component: Registration,
    props: true
  },
  {
    path: '/forgot/',
    name: 'Forgot Password',
    component: Forgot,
    props: true
  },
  {
    path: "/error/:error",
    name: "Error Page",
    component: ErrorPage,
    props: true
  },

  { path: '*', component: ErrorPage }
];

const router = new VueRouter({
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.path == from.path && to.query.step) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  if (Cookies.get('jwt')) {
    await auth.checkAuth();

    if (auth.user.authenticated) {
      return next();
    }
  }

  if (to.matched.some(record => record.meta.auth)) {
    if (!auth.user.authenticated) {
      if (Cookies.get('jwt')) {
        await auth.checkAuth();

        if (auth.user.authenticated) {
          return next();
        }
      }

      if (to.path !== '/login') { // to prevent calling next('login') when already going to /login
        next({
          path: '/login/',
        });
      }
    }
  }

  next();// always call next() last - if no condition applies, we are moving on...
});

export default router;
