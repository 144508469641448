<template>
  <div id="app">
    <header-section v-if="!isFullPageLayout" />
    <transition name="page" mode="out-in" appear>
      <router-view />
    </transition>
    <footer-section v-if="!isFullPageLayout" />
  </div>
</template>

<script>
import FooterSection from '@/components/organism/FooterSection';
import HeaderSection from '@/components/organism/HeaderSection';

const fullPageLayouts = [
  'Login',
  'Registration'
];

export default {
  components: {
    FooterSection,
    HeaderSection
  },
  computed: {
    isFullPageLayout() {
      return fullPageLayouts.includes(this.$route.name);
    }
  }
};
</script>
