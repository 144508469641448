<template>
  <footer>
    <div class="footer-links">
      <router-link to="/why-ukraine/?step=aboutUkraine" class="animated-link">
        {{ $t('sectionLinks.whyUkraine') }}
      </router-link>
      <router-link to="/incentives/?step=lawIncentive" class="animated-link">
        {{ $t('sectionLinks.incentives') }}
      </router-link>
      <router-link to="/locations/" class="animated-link">
        {{ $t('sectionLinks.locations') }}
      </router-link>
      <router-link to="/partners/" class="animated-link">
        {{ $t('sectionLinks.partners') }}
      </router-link>
      <router-link to="/permits/?step=visas" class="animated-link">
        {{ $t('sectionLinks.permits') }}
      </router-link>
      <router-link to="/productions/?step=films" class="animated-link">
        {{ $t('sectionLinks.filmedIn') }}
      </router-link>
      <router-link to="/about-project/?step=aboutUs" class="animated-link">
        {{ $t('sectionLinks.about') }}
      </router-link>
    </div>
    <div class="footer-contacts text-center">
      <div>{{ $t('footer.infoText') }}</div>

      <div class="logos my-4">
        <a href="https://mpa.org.ua/" target="_blank" rel="noreferrer noopener"><img src="/static/images/logo_umpa.png" class="logoUMPA mx-0 mx-sm-5" alt="UMPA" /></a>
        <a href="http://cepukraine.org/" target="_blank" rel="noreferrer noopener"><img src="/static/images/logo_usaid_v2.png" class="logoUSAID mx-3 mx-sm-5" alt="USAID" /></a>
        <a href="https://devme.space" target="_blank" rel="noreferrer noopener"><img src="/static/images/logo_devme.png" class="logoDEVME mx-0 mx-sm-5" alt="Devme.space" /></a>
      </div>

      <div class="mt-2">
        <a href="mailto:info@locations.com.ua" target="_blank" rel="noreferrer noopener">{{ $t('footer.contactText') }}</a>

        <a class="ml-4" href="https://www.facebook.com/Locations.UA" target="_blank" rel="noreferrer noopener" role="button" aria-label="Facebook contact"><i class="fab fa-facebook"></i></a>
      </div>
      <div class="text-center">
        <router-link to="/terms-of-service/" class="text-muted mx-2">{{ $t('sectionLinks.termsOfService') }}</router-link>
        <router-link to="/privacy-policy/" class="text-muted mx-2">{{ $t('sectionLinks.privacyPolicy') }}</router-link>
      </div>
      2021 © locations.com.ua
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

footer {
  color: $gray-700;
  background-color: $gray-100;
  padding: 100px 20px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.23px;
  .footer-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 800px;
    margin: 0 auto;
    a {
      color: $body-color;
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: -0.35px;
      white-space: nowrap;
    }
  }
  .footer-contacts {
    text-align: center;
    margin-top: 40px;
    a {
      text-decoration: underline;
      font-weight: 600;
      display: inline-block;
      margin-bottom: 20px;
    }

    .logos {
      .logoUMPA {
        height: 70px;
      }
      .logoUSAID {
        height: 70px;
      }
      .logoDEVME {
        width: 112px;
      }
    }

    .fa-facebook {
      color: #4267B2;
    }
  }
  @media (max-width: 768px) {
    padding: 40px 20px 65px;
    .footer-links {
      flex-direction: column;
      align-items: center;
      a {
        font-size: 16px;
        line-height: 2.5;
      }
    }
  }
  @media (max-width: 370px) {
    .logos {
      .logoUMPA {
        height: 50px;
        margin-left: 0 !important;
      }
      .logoUSAID {
        height: 50px;
      }
      .logoDEVME {
        width: 90px;
        margin-right: 0 !important;
      }
    }
  }
}
</style>

<script>

export default {
  components: {

  },
  props: [],
  data() {
    return {

    };
  },
  computed: {

  },
  watch: {

  },
  methods: {

  }
};
</script>
