import Vue from "vue";
import App from "./App.vue";
import restClient from './restClient';
import httpRequest from './httpRequest';
import VueResource from 'vue-resource';
import router from "./router";
import Vuelidate from 'vuelidate';
import i18n from './i18n';
import './vue-notify';
import numbro from 'numbro/dist/numbro.js';
import auth from './auth';
import VueGtag from "vue-gtag";
import VueLazyload from 'vue-lazyload';

Vue.prototype.$auth = auth;

Vue.use(VueResource);
Vue.use(Vuelidate);
Vue.use(VueLazyload);
Vue.use(httpRequest);
Vue.use(restClient, router);

if (process.env.NODE_ENV == 'production') {
  Vue.use(VueGtag, {
    config: { id: "G-Z4FPEW6JR9" }
  }, router);
}


Vue.config.productionTip = false;

import 'bootstrap';

import 'fontsource-inter/100.css';
import 'fontsource-inter/200.css';
import 'fontsource-inter/300.css';
import 'fontsource-inter/400.css';
import 'fontsource-inter/500.css';
import 'fontsource-inter/600.css';
import 'fontsource-inter/700.css';
import 'fontsource-inter/800.css';
import 'fontsource-inter/900.css';

import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import '@fortawesome/fontawesome-free/css/brands.css';

import './assets/styles/main.scss';

const EventBus = new Vue();

Vue.use({
  install: function (VueInstance) {
    Object.defineProperties(VueInstance.prototype, {
      // global events bus
      $bus: {
        get: function() {
          return EventBus;
        }
      },

      // uniqueId('some-id') method for non-repeatable html ids
      uniqueId: {
        enumerable: false,
        writable: true,
        value: function(idValue) {
          const prefix = idValue ? idValue + '-' : '';
          return prefix + this._uid;
        }
      },

      $formatNumber: {
        enumerable: false,
        writable: true,
        value: function (value, defaultValue = 'n/a') {
          if (typeof (value) == 'undefined' || value === null || isNaN(value)) {
            return defaultValue;
          }

          return numbro(value).format('0,0');
        }
      },
      $getPartsForArchitectureStyle: {
        enumerable: false,
        writable: true,
        value: function (fullStyle) {
          let mainPart = fullStyle.substr(0, fullStyle.indexOf('('));
          let descriptionPart = fullStyle.replace(mainPart, '');

          if (!mainPart) {
            mainPart = descriptionPart;
            descriptionPart = "";
          }

          return { mainPart, descriptionPart };
        }
      }
    });
  }
});

new Vue({
  i18n,
  router,
  data() {
    return {
      categories: null,
      regions: null,
      productions: null
    }
  },
  created() {
    this.getCommonData();

    // FIXME: remove after refresh token implemented
    this.$auth.refresh();
  },
  methods: {
    async getCommonData() {
      const regionsResult = await this.$restClient.get({
        url: "/region/list/"
      });

      this.$root.regions = regionsResult;

      const categoriesResult = await this.$restClient.get({
        url: "/category/list/"
      });

      this.$root.categories = categoriesResult;

      const productionsResult = await this.$restClient.get({
        url: "/production/list/"
      });

      this.$root.productions = productionsResult;
    }
  },
  render: h => h(App)
}).$mount("#app");
